<template>
  <v-app>
    <dashboard-core-app-bar v-model="expandOnHover" />

    <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" />

    <dashboard-core-view />

    <!-- <dashboard-core-settings v-model="expandOnHover" /> -->
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',
    title: () => (
      location.hostname.includes('hypertex.it') ? 'B2B Hypertex' : 'B2B Lorenzi'
    ),

    components: {
      DashboardCoreAppBar: () => import('./components/core/AppBar'),
      DashboardCoreDrawer: () => import('./components/core/Drawer'),
      // DashboardCoreSettings: () => import('./components/core/Settings'),
      DashboardCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      expandOnHover: false,
    }),

    created () {
      this.$vuetify.theme.dark = true
    },
  }
</script>
